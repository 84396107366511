<i18n>
ru:
  email: Эл. почта
  name: Имя
  phoneNumber: Телефон
  title: "Ваши контакты"
ua:
  email: Ел. пошта
  name: Ім’я
  phoneNumber: Телефон
  title: "Ваші контакти"
us:
  email: E-mail
  name: Name
  personCount: Person count
  title: "Your contacts"
</i18n>

<template>
  <div class="v-row">
    <div
      class="v-col-12 v-typography-title-3 v-mb-sm"
      v-html="translate('personalInfo.title')"
    />
    <div class="v-col-6 v-col-md-4">
      <v-input
        :class-name="`v-arora-input v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}`"
        required
        v-form-validator="{
          Form: validatorForm,
          Value: name,
          Required: true,
          Validations: ['text', 'length'],
          MaxLength: 60
        }"
        :label="translate('personalInfo.name')"
        data-test-id="order-user-name"
        v-model:text="name"
      />
    </div>
    <div class="v-col-6 v-col-md-4">
      <template
        v-if="
          appConfig.RestaurantSettingsPreRun.ForbiddenToChangePhoneNumber &&
          accountStore.isLoggedIn
        "
      >
        <common-phone-input
          disabled
          required
          :label="translate('personalInfo.phoneNumber')"
          :validator-form="validatorForm"
          data-test-id="order-user-phone"
          v-model:phone="phone"
        >
          <template #rightEnd>
            <ui-icon
              color="secondary"
              :size="24"
              name="edit"
              @click="
                async () =>
                  await popupStore.showForbiddenPhoneChangePopup(
                    translate('personalInfo.phoneNumberPopupText'),
                    translate('personalInfo.phoneNumberPopupConfirm'),
                    translate('personalInfo.cancel')
                  )
              "
            />
          </template>
        </common-phone-input>
      </template>
      <common-phone-input
        v-else
        required
        :label="translate('personalInfo.phoneNumber')"
        :validator-form="validatorForm"
        data-test-id="order-user-phone"
        v-model:phone="phone"
      />
    </div>

    <div
      v-if="emailVisible"
      class="v-col-6 v-col-md-4"
    >
      <v-email-input
        :class-name="`v-arora-input v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}`"
        v-form-validator="{
          Form: validatorForm,
          Value: email,
          Required: emailFieldRequired,
          Validations: ['email', 'length'],
          MaxLength: 60
        }"
        :label="translate('personalInfo.email')"
        :required="emailFieldRequired"
        data-test-id="order-user-email"
        v-model:text="email"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HasValidatorForm } from '~types/props'

import {
  useCommon,
  useValidationStore,
  VEmailInput,
  vFormValidator,
  VInput
} from '@arora/common'

import { getActivePinia } from 'pinia'
import { ReceiptSendType } from '~api/consts'

defineProps<HasValidatorForm>()

const appConfig = useAppConfig()

const accountStore = useAccountStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()

const validationStore = useValidationStore(getActivePinia())

const { clean, translate } = useI18nSanitized()
const { capitalLetter, debounce } = useCommon()

const nameLocal = ref<string | null>(null)
const name = computed<string>({
  get: () => {
    return (
      nameLocal.value ?? clientStore.ClientState?.data?.StateOrderData?.Name ?? ''
    )
  },
  set: (value) => {
    nameLocal.value = clean(capitalLetter(value ?? ''))
    debounce(() =>
      clientStore.updateOrderData({
        name: nameLocal.value ?? '',
        refreshState: true
      })
    )
  }
})

const phoneLocal = ref<string | null>(null)

const phone = computed<string>({
  get: () => {
    return (
      phoneLocal.value ?? clientStore.ClientState?.data?.StateOrderData?.Phone ?? ''
    )
  },
  set: (value) => {
    phoneLocal.value = clean(value)
    debounce(() =>
      clientStore.updateOrderData({
        phone: phoneLocal.value ?? '',
        refreshState: true
      })
    )
  }
})

const emailVisible = computed<boolean>(
  () =>
    !appConfig.VueSettingsPreRun.PersonalInfoEmailFieldHidden &&
    appConfig.VueSettingsPreRun.ReceiptSendTypes.some(
      (sendType) => sendType.Type === ReceiptSendType.Email
    )
)
const emailFieldRequired = computed<boolean>(
  () =>
    appConfig.VueSettingsPreRun.PersonalInfoEmailFieldRequired ||
    clientStore.ClientState?.data?.StateOrderData?.ReceiptSendType ===
      ReceiptSendType.Email
)

const emailLocal = ref<string | null>(null)

const email = computed<string>({
  get: () => {
    return (
      emailLocal.value ?? clientStore.ClientState?.data?.StateOrderData?.Email ?? ''
    )
  },
  set: (value) => {
    emailLocal.value = clean(value)
    if (validationStore.validateSimple(value, 'email')) {
      debounce(() =>
        clientStore.updateOrderData({
          email: emailLocal.value ?? '',
          refreshState: true
        })
      )
    }
  }
})
</script>
