<template>
  <div class="v-ui-dropdown-menu-wrapper">
    <ui-icon
      color="mono1000"
      :size="24"
      name="moreVert"
      @click="openDropdown"
    />
    <transition
      appear
      mode="out-in"
      name="slidedown"
      @after-enter="animationLock = false"
    >
      <div
        v-if="menuVisible"
        class="v-ui-dropdown-menu"
        v-on-click-outside="onClickOutside"
      >
        <div
          v-for="(item, index) in items"
          :key="`dropdown-menu-item-${uid}-${index}`"
          class="v-ui-dropdown-menu__item"
          :class="{
            disabled: item.disabled
          }"
          @click="
            () => {
              item.onClick()
              menuVisible = false
            }
          "
        >
          <span
            class="v-typography-text-4"
            v-html="sanitize(item.title)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import type { UIDropdownMenuItem } from '~types/props'

import { vOnClickOutside } from '@arora/common'

defineProps<{
  items: UIDropdownMenuItem[]
}>()
const uid = useId()

const { sanitize } = useI18nSanitized()

const menuVisible = ref<boolean>(false)
const animationLock = ref<boolean>(false)

function openDropdown(): void {
  animationLock.value = true
  menuVisible.value = true
}

function onClickOutside(): void {
  if (!animationLock.value) menuVisible.value = false
}
</script>

<style lang="scss">
@use 'assets/mixins';
@use 'assets/variables';

.v-ui-dropdown-menu-wrapper {
  position: relative;

  .v-ui-dropdown-menu {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.57rem;
    background-color: variables.$White;
    box-shadow: variables.$PopupShadow;
    border-radius: variables.$BorderRadiusButton;

    &__item {
      padding: 0.57rem 1.145rem;
      border-radius: variables.$BorderRadiusButton;
      @include mixins.trans;

      &:hover {
        background-color: variables.$Background;
        color: variables.$SecondaryBackgroundColor;
      }
    }
  }
}
</style>
